<script setup lang="ts">
import { ref } from "vue";
import type { Ref } from "vue";
import DynamicCard from "@/components/ui/DynamicCard.vue";
import CopyToClipboardIcon from "@/components/ui/CopyToClipboardIcon.vue";

import QRCode from "qrcode"; // TODO: look for type declarations on DefinitelyTyped

import { useConfigStore } from "@/stores/config-store";
const CONFIG = useConfigStore();

const emit = defineEmits(["stored"]);

type Session = {
  code: string | undefined;
  bpm: number | undefined;
  link: string | undefined;
  linkText: string | undefined;
  qrcode: string | undefined;
};

let session: Session = {
  code: undefined,
  bpm: undefined,
  link: undefined,
  linkText: undefined,
  qrcode: undefined,
};

enum CreateSessionStates {
  none,
  creating,
  success,
  failure,
}
const createSessionState: Ref<CreateSessionStates> = ref<CreateSessionStates>(
  CreateSessionStates.none
);

async function onFormSubmit(event: Event): Promise<void> {
  if (!(event.target instanceof HTMLFormElement)) {
    return;
  }
  // see: https://github.com/Microsoft/TypeScript/issues/29540

  createSessionState.value = CreateSessionStates.creating;

  interface FormData {
    bpm: number;
  }

  const formDataRaw = new FormData(event.target);
  const formData: FormData = {
    bpm: parseInt(formDataRaw.get("bpm") as string),
  };

  try {
    await createSession(formData.bpm);
    emit("stored", session.code);
    createSessionState.value = CreateSessionStates.success;
    startTransition();
  } catch (error) {
    createSessionState.value = CreateSessionStates.failure;
    setTimeout(() => {
      createSessionState.value = CreateSessionStates.none;
    }, 2000);
  }
}

const displayFormElement: Ref<boolean> = ref<boolean>(true);
const displayShareElement: Ref<boolean> = ref<boolean>(false);
const displayFormElementContents: Ref<boolean> = ref<boolean>(true);
const displayShareElementContents: Ref<boolean> = ref<boolean>(false);

function startTransition() {
  displayFormElement.value = false;
  setTimeout(() => {
    displayFormElementContents.value = false;
    displayShareElementContents.value = true;
    setTimeout(() => {
      displayShareElement.value = true;
    }, 1000);
  }, 1000);
}

// TODO: put this function into a store!
async function createSession(bpm: number): Promise<void> {
  const response = await fetch(CONFIG.apiEndpoint + "/sessions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ bpm: bpm }),
  });

  if (response.status === 201) {
    const createdSession = await response.json();

    session.code = createdSession.sessioncode;
    session.bpm = createdSession.bpm;
    session.link = CONFIG.appBaseUri + "/jam/" + createdSession.sessioncode;
    session.linkText = session.link.replace(/^\w+:\/\/(.*)$/, "$1");

    session.qrcode = await new Promise((resolve, reject) => {
      QRCode.toDataURL(
        session.link as string,
        {
          width: 512,
          margin: 2,
          errorCorrectionLevel: "high",
          // see: https://github.com/soldair/node-qrcode#qr-code-options
        },
        function (err: Error | undefined | null, url: string) {
          if (err) reject(err);

          resolve(url);
        }
      );
    });
  }
}
</script>

<template>
  <div>
    <DynamicCard :animation-duration="600">
      <div
        id="form-element"
        :class="displayFormElement ? 'fade-in' : 'fade-out'"
      >
        <div v-if="displayFormElementContents">
          <form id="form-create" @submit.prevent="onFormSubmit" class="h-auto">
            <div class="flex w-full">
              <input
                type="number"
                id="input-bpm"
                name="bpm"
                class="text-right w-full"
                value="96"
                autocomplete="off"
              />
              <label for="input-bpm" class="p-2">bpm</label>
            </div>
            <div class="mt-4 flex flex-row w-full">
              <button
                type="submit"
                id="button-submit"
                class="bg-primary text-white mx-auto min-w-[150px] h-10"
              >
                <div
                  v-if="createSessionState === CreateSessionStates.creating"
                  class="inline-block"
                >
                  <mdicon name="loading" spin />
                </div>
                <div
                  v-if="createSessionState === CreateSessionStates.success"
                  class="inline-block"
                >
                  <mdicon name="check" />
                </div>
                <div
                  v-if="createSessionState === CreateSessionStates.failure"
                  class="leading-3 align-middle"
                >
                  <span class="inline-block align-middle">
                    <mdicon
                      class="inline-block align-middle"
                      name="alert-outline"
                    />
                    <span class="inline align-middle leading-4"> Error!</span>
                  </span>
                </div>
                <p v-else>Create Session</p>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        id="share-element"
        :class="displayShareElement ? 'fade-in' : 'fade-out'"
      >
        <div v-if="displayShareElementContents" class="text-center">
          <div id="code" class="font-bold">{{ session.code }}</div>
          <div id="bpm">{{ session.bpm }} BPM</div>
          <img
            id="qr-code"
            class="aspect-square w-full"
            :src="session.qrcode"
          />
          <span>
            <a id="link" :href="session.link" target="_blank">{{
              session.linkText
            }}</a>
            <CopyToClipboardIcon :text-to-copy="session.link || ''" />
          </span>
        </div>
      </div>
    </DynamicCard>
  </div>
</template>
