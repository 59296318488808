<script setup lang="ts">
import {
  onMounted,
  onUpdated,
  onBeforeUpdate,
  ref,
  getCurrentInstance,
  defineProps,
  computed,
} from "vue";
import type { Ref, ComponentInternalInstance } from "vue";

const props = defineProps({
  animationDuration: {
    /* Time in ms the animation will take */
    type: Number,
    required: false,
    default: 300,
  },
});

const animationDurationCSSValue = computed(() => {
  return props.animationDuration + "ms";
});

const cardElId: string =
  "card-" + (getCurrentInstance() as ComponentInternalInstance).uid;

const currentHeight: Ref<string> = ref<string>("");
const targetHeight: Ref<string> = ref<string>("");
let cardEl: HTMLElement;

onMounted(() => {
  cardEl = document.getElementById(cardElId) as HTMLElement;
  cardEl.style.transitionDuration = animationDurationCSSValue.value;
});

onBeforeUpdate(() => {
  cardEl.style.height = "auto"; // set height back to "auto" to make it adapt to content
  currentHeight.value = window.getComputedStyle(cardEl).height;
});

onUpdated(() => {
  requestAnimationFrame(() => {
    targetHeight.value = window.getComputedStyle(cardEl).height;
    cardEl.style.height = currentHeight.value; // set height explicitly because transitions don't work with "auto"

    requestAnimationFrame(() => {
      cardEl.style.height = targetHeight.value;
    });
  });
});
</script>

<template>
  <div class="p-4 bg-white shadow-lg rounded-xl card" :id="cardElId">
    <slot />
  </div>
</template>

<style scoped type="css">
.card {
  transition-property: height;
  transition-timing-function: ease-in-out;
  overflow-y: hidden;
}
</style>
