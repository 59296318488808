<script setup lang="ts">
import { defineProps } from "vue";

const valuePropositions = [
  "Sing Together On Video Calls!",
  "Don't Miss A Beat In Your Remote Sessions!",
  "Get started for free!",
];

const props = defineProps({
  index: {
    /* The index of the value proposition to display. Defaults to 0. */
    type: Number,
    required: false,
    default: 0,
  },
});
</script>

<template>
  <div
    id="testimonial"
    class="flex flex-col w-full p-4 py-8 text-center font-bogaloo text-4xl text-primary"
  >
    <p>{{ valuePropositions[props.index] }}</p>
  </div>
</template>

<style scoped></style>
