import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import SessionView from "@/views/SessionView.vue";
import DebugView from "@/views/DebugView.vue";

/**
 * Routes can have `meta.title` of type string which will be
 * set as the page title (followed by " JamSync").
 * You can use template tags in the `meta.title` which will
 * be replaced by the corresponding field in `route.params`.
 *
 * Example
 *    "Hello {{ name }}!" => "Hello Kim!" for `route.param.name === "Kim"`
 */

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    // {
    //   path: "/about",
    //   name: "about",
    //   // route level code-splitting
    //   // this generates a separate chunk (About.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import("@/views/ExampleAboutView.vue"),
    //   meta: {
    //     title: "About",
    //   },
    // },
    {
      path: "/jam",
      redirect: "/",
    },
    {
      path: "/jam/:sessionCode",
      name: "session",
      component: SessionView,
      meta: {
        title: "{{ sessionCode }}",
      },
    },
    {
      path: "/debug",
      name: "debug",
      component: DebugView,
      meta: {
        title: "Debug Card",
      },
    },
    {
      path: "/:pathMatch(.*)*",
      name: "catch-all",
      redirect: "/",
    },
  ],
});

router.beforeEach((to, from, next) => {
  let title: string = (to.meta?.title as string) || "";

  // replace template tags with corresponding route param:
  title = title.replace(/{{\s*(\S*)\s*}}/, (match: string, group: string) => {
    return to.params[group] as string;
  });

  title += " JamSync";

  document.title = title;

  next();
});

export default router;
