<script setup lang="ts">
import type { Ref, VNode } from "vue";
import {
  ref,
  useSlots,
  h,
  defineComponent,
  nextTick,
  onMounted,
  getCurrentInstance,
} from "vue";

const slots = useSlots();
const slides: Ref<Array<VNode>> = ref<Array<VNode>>(
  slots.default?.()[0].children as VNode[]
);

const selectedSlide: Ref<number> = ref<number>(1);
const currentSlideInView: Ref<number> = ref<number>(0);

const componentUid = getCurrentInstance()?.uid || 0;

const AugmentedSlides = defineComponent({
  render() {
    // if (!slots.default) {
    //   return undefined;
    // }

    (slides.value as Array<VNode>).map((item: VNode, index: number) => {
      nextTick(() => {
        // this is where you can augment the slides individually:
        if (item && item.el) {
          item.el.id = generateElId("slide", index);
        }
      });
    });

    return h(
      "div",
      {
        id: generateElId("slides"),
        class:
          "overflow-x-scroll flex flex-row snap-x snap-mandatory scroll-smooth scrollbar-hide",
      },
      [slides.value]
    );
  },
});

/**
 * Triggers navigation to previous slide.
 * Will roll over to last slide if triggered at first slide.
 */
function navigateToPrevious() {
  selectedSlide.value =
    currentSlideInView.value - 1 < 0
      ? slides.value.length - 1
      : currentSlideInView.value - 1;
  scrollSlideIntoView(selectedSlide.value);
}

/**
 * Triggers navigation to next slide.
 * Will roll over to first slide if triggered at last slide.
 */
function navigateToNext() {
  selectedSlide.value = (currentSlideInView.value + 1) % slides.value.length;
  scrollSlideIntoView(selectedSlide.value);
}

/**
 * Scroll the specified slide into view on x-axis.
 * If the slide is not fully in view, it will scroll the y-axis as well
 * to move it fully into view.
 *
 * @param index {number} Slide index
 */
function scrollSlideIntoView(index: number): void {
  const el = document.getElementById(generateElId("slide", index));
  el?.scrollIntoView({
    inline: "center",
    behavior: "smooth",
    block: "nearest",
  });
}

/**
 * Generates an element id that is unique to this component instance.
 *
 * @param baseId {string} The id you want to give to this component
 * @param index {number} Running index to add to the id (e.g. in v-for)
 */
function generateElId(baseId: string, index: number = 0): string {
  return baseId + "-" + index + "-" + componentUid;
}

onMounted(() => {
  const slidesViewportEl: HTMLElement = document.getElementById(
    generateElId("slides-viewport")
  ) as HTMLElement;
  const slidesEl: HTMLElement = document.getElementById(
    generateElId("slides")
  ) as HTMLElement;

  const slideCenterpointToleranceX: number = 1;
  slidesEl.addEventListener("scroll", () => {
    const slidesViewportCenterX: number =
      (slidesViewportEl.getBoundingClientRect().right +
        slidesViewportEl.getBoundingClientRect().left) /
      2;

    slides.value.forEach((slide) => {
      const slideCenterX: number =
        (slide?.el?.getBoundingClientRect().right +
          slide?.el?.getBoundingClientRect().left) /
        2;

      if (
        slideCenterX < slidesViewportCenterX + slideCenterpointToleranceX &&
        slideCenterX > slidesViewportCenterX - slideCenterpointToleranceX
      ) {
        currentSlideInView.value = parseInt(
          (slide?.el as HTMLElement).id.replace("slide-", "")
        );
      }
    });
  });
});
</script>

<template>
  <div :id="generateElId('carousel')" class="flex flex-col w-full bg-debug-0">
    <div class="flex flex-row bg-debug-0">
      <div
        :id="generateElId('controls-left')"
        class="h-full flex flex-col justify-center self-center bg-debug-0"
      >
        <button
          @click="navigateToPrevious"
          class="rounded-full w-10 h-10 ml-4 mr-2 text-primary shadow-none flex flex-row justify-center items-center"
        >
          <!-- more prominent option for navigation buttons: class="rounded-full w-10 h-10 ml-2 mr-4 bg-primary text-white shadow-lg flex flex-row justify-center items-center" -->
          <mdicon name="chevron-left"></mdicon>
        </button>
      </div>
      <div
        :id="generateElId('slides-viewport')"
        class="bg-debug-0 flex flex-col min-w-0"
      >
        <AugmentedSlides class="bg-debug-0" />
      </div>
      <div
        :id="generateElId('controls-right')"
        class="h-full flex flex-col justify-center self-center bg-debug-0"
      >
        <button
          @click="navigateToNext"
          class="rounded-full w-10 h-10 ml-2 mr-4 text-primary shadow-none flex flex-row justify-center items-center"
        >
          <mdicon name="chevron-right"></mdicon>
        </button>
      </div>
    </div>
    <div
      :id="generateElId('position-indicator')"
      class="flex flex-row justify-center pt-2 bg-debug-0"
    >
      <div
        v-for="(slide, index) in slides"
        :key="index"
        :id="generateElId('dot', index)"
        :class="
          'w-2 h-2 m-1 rounded-full shadow-lg' +
          (index === currentSlideInView ? ' bg-primary' : ' bg-white')
        "
      ></div>
    </div>
    <!-- <div class="flex flex-row justify-center">
      {{ currentSlideInView + 1 }} / {{ slides.length }}
    </div> -->
    <!-- <div class="flex flex-row justify-center">{{ componentUid }}</div> -->
  </div>
</template>

<style scoped>
:deep(section) {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  scroll-snap-align: center;
}
</style>
