import { defineStore } from "pinia";

function extractBaseUri(baseUrl: string): string {
  const matches: Array<string> | null = baseUrl.match(/^\/?(.*)\/$/);
  const stripped: string = matches && matches[1] ? matches[1] : "/";
  if ([".", "/"].includes(stripped)) return "";
  return "/" + stripped;
}

const prod = process.env.NODE_ENV === "production";

export const useConfigStore = defineStore("config", {
  state: () => {
    return {
      // appBaseUri: "https://frederikheld.github.io/jamsync-client-github.io",
      // appBaseUri: prod
      //   ? "https://jamsync-client.netlify.app"
      //   : "http://localhost:5173",
      // appBaseUri: prod
      //   ? window.location.origin + extractBaseUri(import.meta.env.BASE_URL)
      //   : "http://localhost:5173",
      appBaseUri:
        window.location.origin + extractBaseUri(import.meta.env.BASE_URL),
      apiEndpoint: prod
        ? // "https://jamsync-server.herokuapp.com/api"
          "https://jamsync-server.onrender.com/api"
        : "http://localhost:8080/api",
      timesyncEndpoint: prod
        ? // "https://jamsync-server.herokuapp.com"
          "https://jamsync-server.onrender.com"
        : "http://localhost:8080",
      timesyncInterval: 10000,
    };
  },
  getters: {
    getAppBaseUri: (state) => state.appBaseUri,
    getApiEndpoint: (state) => state.apiEndpoint,
    getTimesyncEndpoint: (state) => state.timesyncEndpoint,
  },
});
