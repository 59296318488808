<script setup lang="ts" allowJS="true">
import { reactive } from "vue";
import { useConfigStore } from "@/stores/config-store";
import { useRoute } from "vue-router";

import JamSyncLogo from "@/components/blocks/JamSyncLogo.vue";
import DynamicCard from "@/components/ui/DynamicCard.vue";

import { type TimeProvider, TimeProviderLocal, TimeProviderSynced } from "@/classes/TimeProvider";
import PrecisionScheduler from "@/classes/PrecisionScheduler";

import BeatAnimation from "@/components/blocks/BeatAnimation.vue";

import { ref } from "vue";
import type { Ref } from "vue";

const CONFIG = useConfigStore();
const route = useRoute();

const animationDuration: Ref<number> = ref(0);
let animate: Ref<number> = ref<number>(5);

let displayBpmElement: Ref<boolean> = ref<boolean>(false);
const bpmString: Ref<string | undefined> = ref<string | undefined>(undefined);

// state management:

enum LoadingState {
  loading_session,
  syncing_time,
  ready,
  failed,
}
let loadingState: Ref<LoadingState> = ref<LoadingState>(
  LoadingState.loading_session
);

enum ReasonOfFailure {
  none,
  session_not_existing,
  loading_failed,
}
let reasonOfFailure: Ref<ReasonOfFailure> = ref<ReasonOfFailure>(
  ReasonOfFailure.none
);

// session data:

type Session = {
  code: string | undefined;
  bpm: number | undefined;
  synctime: number | undefined;
};

const session: Session = reactive({
  code: undefined,
  bpm: undefined,
  synctime: undefined,
});

// define functions:

// TODO: put this function into a store!
/**
 * If session exists: returns a Session object with the data of the session
 * If session does not exist: returns a empty Session object
 * If session could not be loaded (e.g. because server was unavaibalbe or
 * internal server error): trows error
 *
 * @param sessionCode
 */
async function getSession(sessionCode: string): Promise<Session> {
  try {
    const response: any = await fetch(
      CONFIG.apiEndpoint + "/sessions/" + sessionCode
    );

    if (response.status === 200) {
      const loadedSession = await response.json();
      return Promise.resolve({
        code: loadedSession.sessioncode,
        bpm: loadedSession.bpm,
        synctime: loadedSession.synctime,
      });
    } else if (response.status === 404) {
      return Promise.resolve({
        code: undefined,
        bpm: undefined,
        synctime: undefined,
      });
    } else {
      return Promise.reject(new Error("could not load session"));
    }
  } catch (error) {
    return Promise.reject(new Error("could not load session"));
  }
}

// define scheduler:

const timeProvider: TimeProviderLocal = new TimeProviderLocal()
// const timeProvider: TimeProviderSynced = new TimeProviderSynced(CONFIG.timesyncEndpoint)
// timeProvider.startSync()

const scheduler: PrecisionScheduler = new PrecisionScheduler(
  session.synctime as number,
  1, // execute every 100ms
  10, // allow to be off 10ms max before a tick is being skipped
  timeProvider
);

// main function:

async function main() {
  // load stored session:

  await new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000); // minimal loading time for better user feedback
  });

  try {
    const sessionRaw: Session = await getSession(
      route.params.sessionCode as string
    );

    // const sessionRaw: Session = {
    //   code: "BBBBB",
    //   bpm: 24,
    //   synctime: 0,
    // };

    if (!sessionRaw.code) {
      loadingState.value = LoadingState.failed;
      reasonOfFailure.value = ReasonOfFailure.session_not_existing;
      return;
    }

    session.code = sessionRaw.code;
    session.bpm = sessionRaw.bpm;
    session.synctime = sessionRaw.synctime;

    if (session.bpm) animationDuration.value = (60 * 1000) / session.bpm;

    // fade in animation for bpm string:
    bpmString.value = session.bpm + " bpm";
    setTimeout(() => {
      displayBpmElement.value = true;
    }, 300); // delay by the length of the box height transition
  } catch (error) {
    loadingState.value = LoadingState.failed;
    reasonOfFailure.value = ReasonOfFailure.loading_failed;
    return;
  }

  loadingState.value = LoadingState.syncing_time;

  // DEBUG wait
  // TODO: await an update event from tc (feature to be implemented in tacitact)
  await new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, 2000);
  });

  loadingState.value = LoadingState.ready;

  // start scheduler (after the height expand transition is finished):
  setTimeout(() => {
    scheduler.start(() => {
      animate.value++;
    }, animationDuration.value);
  }, 300); // delay by the length of the box height transition
}

main();
</script>

<template>
  <section class="container w-full h-screen flex flex-col">
    <div id="logo" class="flex-row py-12">
      <JamSyncLogo />
    </div>
  </section>

  <div
    class="flex justify-center"
    style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"
  >
    <div class="flex flex-col w-72 justify-end sm:justify-center pb-12">
      <DynamicCard>
        <div class="text-center">
          <p class="text-xl font-bold">
            {{ session.code || route.params.sessionCode }}
          </p>
          <!-- <Transition> -->
          <p :class="displayBpmElement ? 'fade-in' : 'fade-out'">
            {{ bpmString }}
          </p>
          <!-- </Transition> -->
        </div>
      </DynamicCard>
      <DynamicCard class="mt-8">
        <div v-if="loadingState === LoadingState.ready">
          <BeatAnimation :animate="animate" :duration="animationDuration" />
        </div>

        <div
          v-else-if="loadingState === LoadingState.failed"
          class="flex flex-column justify-center h-full items-center"
        >
          <div
            v-if="reasonOfFailure === ReasonOfFailure.session_not_existing"
            class="text-center"
          >
            <p class="p-2">Session doesn't exist 🙈</p>
          </div>
          <div v-else>
            <p class="p-2">Loading failed 😥</p>
          </div>
        </div>

        <div v-else class="text-center w-100 px-4 mb-2 mt-4 mx-auto">
          <div v-if="loadingState === LoadingState.loading_session">
            <p class="inline-block animate-spin">
              <mdicon name="loading" />
            </p>
            <p class="mt-2">loading session</p>
          </div>
          <div v-if="loadingState === LoadingState.syncing_time">
            <p class="inline-block animate-breath">
              <mdicon name="timer-music-outline" />
            </p>
            <p class="mt-2">syncing time</p>
          </div>
        </div>
      </DynamicCard>
    </div>
  </div>
</template>

<style lang="css">
.animate-breath {
  animation: breath 1.6s ease-in-out infinite;
}

@keyframes breath {
  0% {
    transform: scale(85%);
  }
  50% {
    transform: scale(115%);
  }
  100% {
    transform: scale(85%);
  }
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}
</style>
