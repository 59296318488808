import type { TimeProvider } from "@/classes/TimeProvider"

class PrecisionScheduler {
  private schedulerInterval: number;
  private schedulerTolerance: number;
  private timeProvider: TimeProvider;
  private executionIsLocked: boolean;
  private syncTime: number;
  private executionTime: number;

  public isRunning: boolean;

  constructor(
    syncTime: number,
    schedulerInterval: number = 100,
    schedulerTolerance: number = 10,
    timeProvider: TimeProvider
  ) {
    // config:
    this.schedulerInterval = schedulerInterval;
    this.schedulerTolerance = schedulerTolerance;
    this.timeProvider = timeProvider;

    // state:
    this.executionIsLocked = false;
    this.isRunning = false;
    this.executionTime = 0;
    this.syncTime = syncTime || this.timeProvider.getTime();
  }

  private async loop(
    functionToExecute: Function,
    interval: number
  ): Promise<boolean> {
    // const performanceStart: number = window.performance.now(); // @TODO: this is the attempt to compensate the syncTime for exectuion time

    const currentLoopTick =
      new Date(this.timeProvider.getTime() + this.executionTime).valueOf() -
      new Date(this.syncTime).valueOf();

    this.timeProvider.getTime();

    if (
      this.executionIsLocked &&
      currentLoopTick % interval > this.schedulerTolerance
    ) {
      this.executionIsLocked = false;
    } else if (
      !this.executionIsLocked &&
      currentLoopTick % interval <= this.schedulerTolerance
    ) {
      this.executionIsLocked = true;
      functionToExecute();

      // this.executionTime = window.performance.now() - performanceStart;
    }

    return await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, this.schedulerInterval);
    });
  }

  async start(functionToExecute: Function, interval: number): Promise<void> {
    this.stop(); // stop a running loops to avoid multiple loops in parallel

    this.isRunning = true; // start loop

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (this.isRunning) {
        await this.loop(functionToExecute, interval);
      } else {
        break;
      }
    }
  }

  stop() {
    this.isRunning = false;
  }
}

export default PrecisionScheduler;
