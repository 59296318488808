<script setup lang="ts">
import CarouselSlider from "@/components/ui/CarouselSlider.vue";

const testimonials = [
  {
    quote:
      '<p>My colleagues insist on singing for everone\'s birthday via video call and it sounds just horrible.</p><p>I asked them to use <span class="font-bogaloo text-primary">JamSync</span> for mine and for the first time I enjoyed it.</p>',
    name: "Kerstin&nbsp;A.",
    image: "testimonial-w-young.jpeg",
  },
  {
    quote:
      '<p>For the first time I was on the receiving side of those serenades and they are as awful as you\'d expect.</p><p>I would really wish for my colleagues to use <span class="font-bogaloo text-primary">JamSync</span> next time.</p>',
    name: "Julian&nbsp;E.",
    image: "testimonial-m-young.jpeg",
  },
  {
    quote:
      '<p>I was surprised how simple <span class="font-bogaloo text-primary">JamSync</span> is. Just create a session, share it with your friends an off you go!</p>',
    name: "Samantha&nbsp;B.",
    image: "testimonial-w-mid.jpeg",
  },
];
</script>

<template>
  <div class="flex flex-col min-w-0 bg-debug-0">
    <CarouselSlider id="carousel" class="bg-debug-0">
      <section
        v-for="(testimonial, index) in testimonials"
        :key="index"
        class="bg-debug-0 px-2 flex flex-col justify-center"
      >
        <div class="source flex flex-col items-center mt-4 mb-2 bg-debug-0">
          <img
            :src="'./testimonials/' + testimonial.image"
            class="rounded-full w-24"
          />
          <span
            class="name font-ichiro text-3xl mt-2"
            v-html="testimonial.name"
          >
          </span>
        </div>

        <blockquote
          class="quote text-center"
          v-html="testimonial.quote"
        ></blockquote>
      </section>
    </CarouselSlider>
  </div>
</template>
