<script setup lang="ts">
import { ref, defineProps } from "vue";
import type { Ref } from "vue";

const props = defineProps({
  textToCopy: {
    type: String,
    required: true,
  },
});

const textCopied: Ref<boolean> = ref<boolean>(false);
async function copyLinkToClipboard() {
  await navigator.clipboard.writeText(props.textToCopy);
  textCopied.value = true;

  setTimeout(() => {
    textCopied.value = false;
  }, 2000);
}
</script>

<template>
  <span
    id="copy-link-to-clipboard"
    title="copy link to clipboard"
    @click="copyLinkToClipboard"
  >
    <mdicon
      v-if="textCopied"
      name="check"
      width="16"
      style="display: inline-block; cursor: pointer; line-height: 16px"
      class="ml-2 h-5 text-secondary-dark hover:text-black"
    />
    <mdicon
      v-else
      name="clipboard-text-multiple-outline"
      width="16"
      style="display: inline-block; cursor: pointer; line-height: 16px"
      class="ml-2 h-5 text-secondary-dark hover:text-black"
    />
  </span>
</template>
