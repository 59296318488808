<template>
  <div id="logo" class="container w-auto h-auto text-center mx-auto">
    <!-- <div
      class="inline-block relative w-auto mx-auto mt-10 px-6 pt-2 pb-4 before:-inset-1 before:rounded-bl-2xl before:rotate-[3deg] before:block before:absolute before:-skew-y-6 before:bg-primary before:ring-4 before:ring-primary before:ring-offset-4 before:ring-offset-primary-light drop-shadow-xl"
    >
      <span
        class="relative text-5xl font-bold text-primary-light underline underline-offset-4"
      > -->
    <div
      class="inline-block relative w-auto mx-auto mt-10 px-4 py-2 before:-inset-1 before:rounded-bl-2xl before:rotate-[3deg] before:block before:absolute before:-skew-y-6 before:bg-primary before:ring-4 before:ring-primary before:ring-offset-4 before:ring-offset-white drop-shadow-xl"
    >
      <span class="relative text-5xl font-bogaloo text-white">JamSync</span>
    </div>
  </div>
</template>
