<script setup lang="ts">
import { useRouter } from "vue-router";
import DynamicCard from "@/components/ui/DynamicCard.vue";

const router = useRouter();

const props = defineProps<{
  sessionCode: string | undefined;
}>();

function onFormSubmit(event: Event): void {
  if (!(event.target instanceof HTMLFormElement)) {
    return;
  }
  // see: https://github.com/Microsoft/TypeScript/issues/29540

  interface FormData {
    sessionId: string;
  }
  const formDataRaw = new FormData(event.target);
  const formData: FormData = {
    sessionId: formDataRaw.get("sessionid") as string,
  };

  router.push("jam/" + formData.sessionId);
}

function toUpperCase(event: Event): void {
  if (event?.target instanceof HTMLInputElement) {
    if (event?.target?.value) {
      event.target.value = event.target.value.toUpperCase();
    }
  }
}
</script>

<template>
  <DynamicCard>
    <form id="form-create" @submit.prevent="onFormSubmit">
      <div class="flex flex-row">
        <input
          type="text"
          id="input-session-id"
          name="sessionid"
          class="text-center grow"
          placeholder="session id"
          :value="props.sessionCode"
          maxlength="5"
          @input="toUpperCase($event)"
        />
      </div>
      <div class="flex flex-row mt-4">
        <button
          type="submit"
          id="button-submit"
          class="bg-primary text-white mx-auto"
        >
          Join Session
        </button>
      </div>
    </form>
  </DynamicCard>
</template>
