<script setup lang="ts">
import CarouselSlider from "@/components/ui/CarouselSlider.vue";

const memes = [
  // {
  //   alttext:
  //     "Two Buttons meme. Tough decision: Sing horribly out of sync on video calls or use JamSync?",
  //   image: "two-buttons.jpeg",
  // },
  {
    alttext:
      "Drake Hotline Bling meme. Dislike: Sing horribly out of sync on video calls. Like: Use JamSync!",
    image: "drake-hotline-bling.jpeg",
  },
  {
    alttext:
      "Left Exit 12 Off Ramp meme. On your birthday video call, your team will rather sing horribly out of rythm than going for the obvious choice JamSync.",
    image: "left-exit-12-off-ramp.jpeg",
  },
  {
    alttext:
      "Kombucha Girl meme. Jamming on video calls: disgust. After trying it with JamSync: reconsider.",
    image: "kombucha-girl.jpg",
  },
  {
    alttext:
      "Is This A Pigeon meme. You jamming with your friends on a video call using JamSync, wondering if this is perfection.",
    image: "is-this-a-pigeon.jpg",
  },
];
</script>

<template>
  <div class="flex flex-col">
    <CarouselSlider id="carousel">
      <section v-for="(meme, index) in memes" :key="index">
        <div class="mx-auto self-center">
          <img :src="'./memes/' + meme.image" :alt="meme.alttext" />
        </div>
      </section>
    </CarouselSlider>
  </div>
</template>

<style scoped></style>
