<script lang="ts" setup>
import { onMounted, ref } from "vue";
import type { Ref } from "vue";

const initialIconElPosY: Ref<number> = ref<number>(0);
const iconEl: Ref<HTMLElement> = ref<HTMLElement>(
  document.createElement("div")
);

function scrollHandler() {
  const scrollTop = document.documentElement.scrollTop;
  const opacity = 1 - scrollTop / 100;

  iconEl.value.style.opacity = opacity >= 0.1 ? opacity.toString() : "0.0";
}

onMounted(() => {
  iconEl.value = document.getElementById("scroll-nudge-icon") as HTMLElement;
  initialIconElPosY.value = iconEl.value.getBoundingClientRect().top;
  window.addEventListener("scroll", scrollHandler);
});
</script>

<template>
  <div class="py-6 flex justify-center text-primary animate-nudge-bounce">
    <mdicon id="scroll-nudge-icon" name="chevron-double-down"></mdicon>
  </div>
</template>
