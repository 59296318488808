// import { Client } from "tacitact"

interface TimeProvider {
  getTime (): number
}

class TimeProviderLocal implements TimeProvider {

  constructor () {}

  public getTime (): number {
    return Date.now()
  }
}

class TimeProviderSynced implements TimeProvider {
//   private tc: Client

//   constructor (timesyncEndpoint: string) {
//     this.tc = new Client(timesyncEndpoint)
//   }

  public getTime(): number {
      // return this.tc.getTime()
      return 0 // DEBUG
  }

  // public startSync(): void {
    // this.tc.startSync()
  // }
}

export {
  TimeProviderLocal,
  TimeProviderSynced,
  type TimeProvider
}
