<script setup lang="ts">
import { ref, computed, watch } from "vue";
import type { Ref, ComputedRef } from "vue";

const props = defineProps({
  animate: {
    type: Number,
    required: true,
  },
  duration: {
    type: Number,
    required: true,
  },
});

const animate: ComputedRef<number> = computed(() => props.animate);
const animationIsActive: Ref<boolean> = ref(false);

function stopAnimation() {
  animationIsActive.value = false;
  // console.log("[BeatAnimation] stopped");
}

let stopTimeout: number;
function startAnimation() {
  if (stopTimeout) {
    clearTimeout(stopTimeout);
    stopAnimation();
  }

  animationIsActive.value = true;
  // console.log("[BeatAnimation] triggered");

  stopTimeout = window.setTimeout(() => {
    stopAnimation();
  }, props.duration);
}

watch(animate, () => {
  startAnimation();
});
</script>

<template>
  <div class="w-full">
    <div id="beat-indicator">
      <div id="animation">
        <div class="ball-container">
          <div
            :class="'ball' + (animationIsActive ? ' animate' : '')"
            :style="'animation-duration: ' + props.duration + 'ms'"
          ></div>
        </div>
      </div>
      <div class="groundline"></div>
    </div>
    <!-- <div>{{ animate }}, {{ animationIsActive }}</div> -->
  </div>
</template>

<style lang="css">
#animation {
  box-sizing: border-box;
  height: 248px;
}

.ball-container {
  margin-left: auto;
  margin-right: auto;
  padding-top: 24px;
  height: 100%;
  width: 48px;
}

.ball-container .ball {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: red;
  transform: translateY(176px);
}

.animate {
  animation: moving-ball ease-in-out infinite;
}

.groundline {
  width: 128px;
  margin-left: auto;
  margin-right: auto;
  background-color: #000;
  height: 4px;
  box-sizing: border-box;
}

@keyframes moving-ball {
  0% {
    transform: translateY(176px);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(176px);
  }
}
</style>
