<script setup lang="ts">
import { ref } from "vue";
import type { Ref } from "vue";
import CreateSession from "@/components/blocks/CreateSession.vue";
import JoinSession from "@/components/blocks/JoinSession.vue";

import JamSyncLogo from "@/components/blocks/JamSyncLogo.vue";
import ScrollNudge from "@/components/blocks/ScrollNudge.vue";
import ValueProposition from "@/components/blocks/ValueProposition.vue";
import TestimonialsCarousel from "@/components/blocks/TestimonialsCarousel.vue";
import MemesCarousel from "@/components/blocks/MemesCarousel.vue";
import CallToAction from "@/components/blocks/CallToAction.vue";

let sessionCode: Ref<string> = ref("");

function onSessionStored(code: string): void {
  sessionCode.value = code;
}

function scrollToTop(): void {
  window.scrollTo({ top: 0, behavior: "smooth" });
}
</script>

<template>
  <section class="container w-full h-screen flex flex-col">
    <div id="logo" class="flex-row py-12">
      <JamSyncLogo />
    </div>
    <div id="session-management" class="grow flex flex-col justify-center">
      <CreateSession
        class="card w-72 hover:w-128 mx-auto"
        @stored="onSessionStored"
      />
      <JoinSession class="card w-72 mx-auto mt-8" :sessionCode="sessionCode" />
    </div>
    <div id="scroll-nudge" class="flex-row">
      <ScrollNudge />
    </div>
  </section>

  <section>
    <div id="value-proposition-1" class="flex flex-row my-2">
      <ValueProposition />
    </div>
  </section>

  <section>
    <div id="testimonials" class="flex flex-row my-2">
      <TestimonialsCarousel />
    </div>
  </section>

  <section>
    <div id="value-proposition-2" class="flex flex-row my-2">
      <ValueProposition :index="1" />
    </div>
    <div class="flex-row mx-4 mt-0 mb-8 text-center">
      <p>
        Making music together in remote sessions is tricky because everyone has
        a different latency depending on their connection and setup.
      </p>

      <p class="mt-2">
        There's no way to jam in sync without some technical help.
      </p>

      <p class="mt-2">
        <span class="font-bogaloo text-primary">JamSync</span> is the help
        you're looking for!
      </p>
    </div>
  </section>

  <section>
    <div id="memes" class="flex flex-row my-2">
      <MemesCarousel />
    </div>
  </section>

  <section>
    <div id="value-proposition-2" class="flex flex-row my-2">
      <ValueProposition :index="2" />
    </div>
  </section>

  <section>
    <div class="bg-debug-0 text-center my-2">
      <!-- <p><b>Get started for free!</b></p> -->
      <ul>
        <li>No installation</li>
        <li>No registration</li>
        <li>Be ready to jam in less than a minute</li>
      </ul>
    </div>
  </section>

  <section>
    <div id="memes" class="flex flex-row my-2">
      <CallToAction :action-click="scrollToTop" />
    </div>
  </section>
</template>

<style lang="css" scoped>
.card {
  transition: transform 200ms ease-in-out;
}

.card:hover,
.card:focus-within {
  transform: scale(1.1);
}
</style>
