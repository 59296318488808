<script setup lang="ts">
import { RouterView, RouterLink, useRoute } from "vue-router";

const route = useRoute();
</script>

<template>
  <header class="fixed w-full" style="z-index: 1000">
    <nav class="p-3 text-secondary-dark">
      <RouterLink
        to="/"
        class="ml-2 mt-1 inline-block text-center w-10 h-10 pt-1"
        v-if="route.name !== 'home'"
      >
        <span
          class="p-1 rounded-md active:transition-all active:scale-125 active:blur betterhover:hover:transition-all betterhover:hover:scale-125 betterhover:hover:blur inline-block w-auto"
        >
          <mdicon name="home-outline" alt="home" />
        </span>
      </RouterLink>
    </nav>
  </header>

  <main class="w-full h-full max-w-[960px] mx-auto">
    <RouterView />
  </main>
</template>

<style scoped>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
